<script>
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';

export default {
	extends: GlobalVue,
	data() {
		return {
			data:{},
		}
	},
	async mounted() {
		await this.ready()
		this.getApi()
	},
	methods: {
		getApi(){
			Gen.apirest("/home-slider", {},(err,resp)=>{
				if(err) console.log("home slider error "+err)
				this.data = resp.data
				setTimeout(()=>{
					global.SEMICOLON.initialize.fullScreen()
					Gen.loadScript("/frontend/js/custom.js", false)
					$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
					global.SEMICOLON.slider.init();
				}, 100)
			})
		}
	},
}
</script>
<template>
	<section id="slider" class="slider-element slider-parallax swiper_wrapper force-full-screen full-screen clearfix home-slider"
		data-loop="true" data-autoplay="5000">
		<div class="swiper-container swiper-parent">
			<div class="swiper-wrapper" >
				<a v-for="(v,k) in data" data-aos="zoom-out" class="swiper-slide dark" :target="v.target" :style="'background-image: url('+uploader(isMobile || isTablet?v.ahh_img_mobile:v.ahh_img)+');'" :href="v.ahh_link">
					<div class="container clearfix"></div>
				</a>
			</div>
			<div class="swiper-pagination"></div>
		</div>
	</section>
</template>